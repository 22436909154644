import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import GeStudentApiService from "@/core/services/geStudentApi.service";
import { PedagogicalDiaryApiService } from "@/core/services/pedagogicalDiaryApi.service";
import { FormApiService } from "@/core/services/formApi.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

import dayjs from "dayjs";
import "dayjs/locale/es";
dayjs.locale("es");

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;
import Swal from "sweetalert2";

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import "@/core/plugins/vuelidate";
import "@/core/plugins/v-mask";

import { mapGetters } from "vuex";

import FormGroup from "@/view/components/FormGroup";
import json from "highlight.js/lib/languages/json";

Vue.component("FormGroup", FormGroup);

// API service init
GeStudentApiService.init();
PedagogicalDiaryApiService.init();
FormApiService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

Vue.mixin({
  data() {
    return {
      colors: [
        "indigo",
        "red",
        "pink",
        "blue",
        "light-green",
        "deep-purple",
        "cyan",
        "teal",
        "green",
        "blue-grey",
        "orange",
        "pink",
      ],
    };
  },
  created: function () {
    // Setting page title
    const { title } = this.$options;
    if (title) {
      const tt = typeof title === "function" ? title.call(this) : title;
      if (tt) {
        document.title = title;
      }
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },
  methods: {
    fireToast({ icon, title }) {
      Swal.fire({
        icon,
        title,
        toast: true,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        timerProgressBar: true,
      });
    },

    sweetAlertResponse({ status, message }) {
      const title = status ? "¡Éxito!" : "Oops!";
      const icon = status ? "success" : "error";
      Swal.fire({
        title,
        text: message,
        icon,
      });
    },

    /**
     * Function that filter data through v-select filters
     * @param {*} filters Object with pairs of key-value
     * @param {*} data Data to filter
     * @returns filtered data
     */
    filterData(filters, data) {
      // Creating copy of the data without reactivity
      let dataArr = [...data];
      // Removing undefined or null attributes
      let filtersObj = Object.fromEntries(
        Object.entries(filters).filter(
          ([_, value]) => value != null || value != undefined
        )
      );
      // Iterate through filters
      Object.entries(filtersObj).forEach(([key, value]) => {
        dataArr = dataArr.filter((item) => {
          // Check if item has not the current key
          if (!item.hasOwnProperty(key)) {
            return dataArr;
          }
          // Check if filter value is an array
          if (Array.isArray(value)) {
            // Check length of the array
            if (!value.length) {
              return dataArr;
            }
            // Iterate each value of the array
            for (const iterator of value) {
              // Check if exist a coincidence
              if (item[key] == iterator) {
                return item;
              }
            }
          } else {
            return item[key] == value;
          }
        });
      });

      return dataArr;
    },
    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.fireToast({
          icon: "success",
          title: "Texto copiado en el portapapeles",
        });
      } catch ($e) {
        this.fireToast({
          icon: "error",
          title: "No ha sido posible copiar el texto",
        });
      }
    },
    existInArray(search, arr) {
      return arr.some((item) => {
        return Object.values(item).indexOf(search) >= 0;
      });
    },
    calculateAge(birthday) {
      const today = new Date();
      const birthDate = new Date(birthday);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    toFormData(obj) {
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      return formData;
    },
    // takes a {} object and returns a FormData object
    objectToFormData(obj, form, namespace) {
      let formData = form || new FormData();
      let formKey;

      for (let property in obj) {
        if (!obj.hasOwnProperty(property) && !obj[property]) {
          if (typeof obj[property] != "number") {
            continue;
          }
        }
        if (namespace) {
          formKey = namespace + "[" + property + "]";
        } else {
          formKey = property;
        }

        // if the property is an object, but not a File, use recursivity.
        if (obj[property] instanceof Date) {
          formData.append(formKey, obj[property].toISOString());
        } else if (
          typeof obj[property] === "object" &&
          !(obj[property] instanceof File)
        ) {
          this.objectToFormData(obj[property], formData, formKey);
        } else {
          // if it's a string or a File object
          formData.append(formKey, obj[property]);
        }
      }

      return formData;
    },

    getRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },

    getScoreColor(subType, score) {
      if (subType == 1) {
        if (score < 3.5) {
          return "red";
        } else {
          return "green";
        }
      } else if (subType == 2) {
        if (score < 5.5) {
          return "red";
        } else {
          if (score > 5.49 && score < 6.0) {
            return "orange";
          } else {
            if (score > 5.9) {
              return "green";
            } else {
              return "grey";
            }
          }
        }
      }
    },

    toUSDFormat(str) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });

      return formatter.format(str);
    },

    fullFormattedDate(date, returnedFormat) {
      // Set the locale to Spanish
      dayjs.locale('es');

      // Check if the returnedFormat contains a time-related specifier
      const includeTime = /h|m|s|A|a/g.test(returnedFormat);

      // Format the date with or without time based on the condition
      const formattedDate = dayjs(date).format(includeTime ? returnedFormat : 'dddd D [de] MMMM [del] YYYY');

      return formattedDate;
    },

    setCodeTypeTheme(codeTypeName) {
      let codeTypeSchemas = {
        Positivo: {
          color: "green",
          icon: "star",
        },
        Leve: {
          color: "orange",
          icon: "star-minus",
        },
        Grave: {
          color: "red",
          icon: "star-minus",
        },
        "Muy grave": {
          color: "purple",
          icon: "star-minus",
        },
      };

      let defaultSchema = {
        color: "grey",
        icon: "star-minus",
      };

      if (codeTypeSchemas.hasOwnProperty(codeTypeName)) {
        return codeTypeSchemas[codeTypeName];
      } else {
        return defaultSchema;
      }
    },



    //recursive merge sort function for objects
    //Overview

    /* 
    Parameters:
    -arr: (array) the array we want to sort
    -key: (string) allows you to specify the key within each object that you want to use for sorting. 
          By passing different key values, you can sort the array based on different 
          properties of the objects.
    -order: ('asc' or 'desc' string) the sorting order
    -keyName: is used when the values of the specified "key" are equal. It represents the name of 
              the key-value pair in the object that you want to use as a secondary sorting criterion. 
              This allows you to further sort the objects based on another property when the primary 
              sort key values are the same.
    */

    //1st part
    mergeSort(arr, key, order, keyName) {
      if (arr.length <= 1) {
        return arr;
      }

      const mid = Math.floor(arr.length / 2);
      const left = arr.slice(0, mid);
      const right = arr.slice(mid);

      return this.merge(this.mergeSort(left, key, order, keyName), this.mergeSort(right, key, order, keyName), key, order, keyName);
    },

    //2nd part
    merge(left, right, key, order, keyName) {
      let result = [];
      let i = 0;
      let j = 0;

      while (i < left.length && j < right.length) {
        const varA = (typeof left[i][key] === 'string') ? left[i][key].toUpperCase() : left[i][key];
        const varB = (typeof right[j][key] === 'string') ? right[j][key].toUpperCase() : right[j][key];


        let comparison = 0;

        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }

        if (order === 'desc') {
          comparison *= -1;
        }

        if (comparison === 0) {
          // If the values are equal, compare based on the keyName
          const nameA = (left[i][keyName] && typeof left[i][keyName] === 'string') ? left[i][keyName].toUpperCase() : left[i][keyName];
          const nameB = (right[j][keyName] && typeof right[j][keyName] === 'string') ? right[j][keyName].toUpperCase() : right[j][keyName];


          if (nameA > nameB) {
            comparison = 1;
          } else if (nameA < nameB) {
            comparison = -1;
          }
        }

        if (comparison < 0) {
          result.push(left[i]);
          i++;
        } else {
          result.push(right[j]);
          j++;
        }
      }

      return result.concat(left.slice(i)).concat(right.slice(j));
    }

  },
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
